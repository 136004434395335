.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
    min-height: 0;
    height: auto !important; 
}

.kmbVPi {
     overflow: revert !important; 
     white-space: revert !important; 
     text-overflow: revert !important; 
}

.fQkkzS {
    overflow: unset !important;
    white-space: unset !important;
    text-overflow: unset !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
    color: #6e6b7b !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    letter-spacing: 0.5px !important;
    text-transform: capitalize !important;
}