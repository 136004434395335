@import "../node_modules/prismjs/themes/prism-tomorrow";
//@import './assets/scss/app.scss';
@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "@core/scss/react/libs/tables/react-dataTable-component.scss";

// Phone Input styling starts

.phone-input {
  width: 100% !important;
}

[dir] .react-tel-input .flag-dropdown {
  padding: 0;
  background-color: transparent !important;
  border: unset !important;
}

[dir] .react-tel-input .selected-flag:hover,
[dir] .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
[dir] .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

[dir] .dark-layout .react-tel-input .country-list {
  padding: 0;
  background-color: #161d31 !important;
}

[dir] .dark-layout .react-tel-input .flag-dropdown.open .selected-flag {
  background: #161d31;
}

[dir] .react-tel-input .form-control:focus {
  border-color: #7367f0 !important;
}
[dir] .react-tel-input .form-control {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  height: 38px;
}

// Phone Input styling ends

// Swal 2 action buttons styling starts

.swal2-actions {
  gap: 10px;
}

// Swal 2 action button styling ends

// Scrollbar styling starts

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
  background: #f1f1f1;
  border-left: 1px solid darken(rgba(0, 0, 0, 0.075), 60%);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: darken(#949494, 90%);
  border-radius: 10px;
  background: #949494;
}

// Scrollbar styling ends

// Brand Text styling starts

.brand-text {
  text-transform: uppercase;
  font-family: "Great Vibes", cursive;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 0px;
  text-align: center;
}

.brand-logo .brand-text {
  text-shadow: 0 2px 5px #fff;
}

// Brand Text styling ends

// Main Login Background styling starts

.mainLoginBg {
  background: url("/assets/images/LoginBG.jpg") no-repeat center;
  background-size: cover;
}

// Main Login Background styling starts

.css-1s2u09g-control {
  background-color: transparent;
  border-color: #4b4b4b !important;
}

// Styling for Swal starts

.dark-layout {
  .swal2-container.swal2-center > .swal2-popup {
    background-color: #161d31 !important;
  }
}

// Styling for Swal ends

.mt-4px {
  margin-top: 4px;
}

// Freezing columns in Table styling starts

.freezing_columns_container {
  position: absolute;
  background-color: white;
  z-index: 99;
  top: 56px;
}

.freezing_columns_container--table {
}

.freezing_columns_container--thead {
}

.freezing_columns_container--th {
  padding: 25px !important;
  min-width: 200px;
  max-width: 200px;
}

.freezing_columns_container--tbody {
}

.freezing_columns_container--tr {
}

.freezing_columns_container--td {
  padding-top: 16.2px !important;
  padding-bottom: 16.2px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
  max-width: 200px;
}

.dark-layout {
  .freezing_columns_container {
    position: absolute;
    background-color: #283046 !important;
    z-index: 99;
    top: 56px;
  }

  .freezing_columns_container--th {
    color: #6b6879 !important;
  }

  .freezing_columns_container--td {
    background-color: #283046 !important;
  }
}

.handsontable th {
  white-space: normal !important;
  font-weight: bold !important;
  color: #777483 !important;
}

.screening-record-date-tooltip-eye {
  margin-left: 5px;
  margin-bottom: 2px;
}

// Handsontable styling starts

.mt-5px {
  margin-top: 5px !important;
}

.dark-layout {
  .handsontable td,
  .handsontable th {
    background-color: #283046 !important;
    color: white !important;
  }
}

// Handsontable styling ends

table.htCore > thead > tr > th > div.relative > span {
  display: flex !important;
}

table.htCore > thead > tr > th > div.relative > span > .btn-sorting {
   
  .fa-sort {
    &-active{
      color: $primary;
    }
    &-asc {
      margin-top: 5px;
    }
    &-desc {
      margin-bottom: 6px !important;
    }
  }
}

.handsontable span.colHeader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 5px !important;
  width: 100% !important;
}

.table_column_header_button {
  background: none;
  // width: 20px;
  // height: 20px;
  padding: 0;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: wheat;
  color: gray;
  // margin-bottom: 5px;
}

.table_column_header_button--div--desc {
  margin-top: -5px !important;
}

@media (max-width: 768px) {
  .freezing_columns_container {
    top: 108px;
  }
}

@media (max-width: 599px) {
  .freezing_columns_container {
    display: none;
  }
}

// Freezing columns in Table styling ends
.htCenter {
  text-align: center;
}
.htMiddle {
  vertical-align: middle;
}



.screening-summary-modal .modal-header .modal-title {
  width: 100% !important;
}
